.App {
  height: 100%;
}

.deskContent {
  display: block;
}

.mobileContent {
  display: none;
}

@media all and (max-width: 720px) {
  .deskContent {
    display: none;
  }

  .mobileContent {
    display: block;
  }
}

.ui.dimmer {
  z-index: 3000 !important;
}

.radio label,
.checkbox label {
  min-height: 0px !important;
}

@media (max-width: 480px) {
  .ms-paper .ms-paper-content-container .ms-paper-content {
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 2em 0px !important;
  }
}

.ms-collapse-nav li.card a.collapsed {
  background-color: #eee;
  color: #424242;
  border-bottom: 1px darkgrey solid;
}

.ms-collapse-nav li.card a.collapsed:hover {
  background-color: #e0a230 !important;
  color: #fff;
}

.ms-collapse-nav li.card ul a:hover {
  background-color: rgba(224, 162, 48, 0.555) !important;
  color: #fff;
}


.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}

.ant-back-top-icon {
  background: none;
  width: auto;
  height: auto;
  margin: auto;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 63px;
  background: #001529;
}

.site-badge-count-4 .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}